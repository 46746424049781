import React, { Component } from "react";
import { connect } from "react-redux";
import { CgProfile } from "react-icons/cg";
import { AiFillHome, AiFillMedicineBox } from "react-icons/ai";
import { TbDiscount2 } from "react-icons/tb";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import map from "../../../assets/images/map.png";
import MedOffer from "./MedOffer";
import { TopStore } from "./TopStore";
import MostOrderMedicines from "./MostOrderMedicines";
import TopDeal from "./TopDeal";
import StoreNearYou from "./StoreNearYou";
import RecommendedStore from "./RecommendedStore";
import AllMedicalStore from "./AllMedicalStore";
// import { AiOutlineCloseCircle } from "react-icons/ai";
import Footer from "../Footer";
// import ProgressiveImage from "react-progressive-image";
import { Link } from 'react-router-dom';
import Truncate from 'react-truncate';
import ContentLoader from 'react-content-loader'
import { getAllMedicineData, getAllTopDeals, getMostOrders, getAllRecommendedMedicines } from '../../../redux/mobile/medicine/action'
import { BiSearch } from "react-icons/bi";
import MedicineDealsToday from "./MedicineDealsToday";
import MedicineGroup from "./MedicineGroup";
import PopularBrands from "./PopularBrands";



export class Medicines extends Component {
    state = {
        viewCart: true,
        loading: false,
        sliders: [],
        stores: [],
        allStores: [],
        mostOrders: [],
        itemGroup: [],
        recommendedMedicines: [],
        address: [],
    }
    closeCart = () => {
        this.setState({ viewCart: false })
    }
    componentDidMount() {
        this.setState({ loading: true });
        this.setState({ address: JSON.parse(localStorage.getItem("userSetAddress")) })
        this.props.getAllMedicineData(JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, sliders: response.payload.allSliders, allStores: response.payload.allStores  });
                // ////// console.log(response, response.payload.allStores);
            } else {
                ////// console.log(response, "API error");
            }
        });
        this.props.getMostOrders(JSON.parse(localStorage.getItem('userSetAddress'))?.latitude, JSON.parse(localStorage.getItem('userSetAddress'))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                // ////// console.log(response.payload);
            } else {
                ////// console.log(response, "API error");
            }
        })
        this.props.getAllRecommendedMedicines(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ allStores: response.payload.allStores });
            } else {
                ////// console.log(response, "API error");
            }
        });
    }
    render() {
        const { user, medicine, mostOrders, topDeals, nearMedicines, recommendedMedicines, itemGroup } = this.props;
        const { address } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="15" y="12" rx="8" ry="8" width="25" height="25" />
                                <rect x="50" y="18" rx="8" ry="8" width="50" height="15" />
                                <rect x="20" y="45" rx="8" ry="8" width="120" height="6" />
                                <rect x="185" y="15" rx="8" ry="8" width="70" height="20" />
                                <rect x="15" y="65" rx="8" ry="8" width="250" height="40" />
                                <rect x="15" y="145" rx="8" ry="8" width="195" height="95" />
                                <rect x="225" y="145" rx="5" ry="5" width="40" height="95" />
                                <rect x="15" y="270" rx="8" ry="8" width="70" height="25" />
                                <rect x="95" y="270" rx="8" ry="8" width="70" height="25" />
                                <rect x="180" y="270" rx="8" ry="8" width="85" height="25" />
                                <rect x="15" y="320" rx="8" ry="8" width="140" height="20" />
                                <rect x="15" y="360" rx="8" ry="8" width="55" height="55" />
                                <rect x="15" y="425" rx="8" ry="8" width="55" height="25" />
                                <rect x="15" y="465" rx="8" ry="8" width="250" height="100" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div style={{ paddingBottom: '21vh' }}>
                                <div className='mobile-home-bg-img py-3 d-flex flex-column px-3 justify-content-between '>
                                    <div className='d-flex flex-row justify-content-between w-100'>
                                        <div className='d-flex flex-row'>
                                            <AiFillMedicineBox style={{ fontSize: '1.8em' }} />
                                            <span className='mobile-span-home ps-2'>Medicine</span>
                                        </div>
                                        <div className='d-flex flex-row align-items-center'>
                                            {user && user.success ? (
                                                <Link to="/my-coupon" className='text-dark'>
                                                    <div>
                                                        <TbDiscount2 style={{ fontSize: "30px" }} />
                                                        <span className='mobile-home-span-3 ps-2 pe-2'>Offer</span>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <Link to="/signup" className='text-dark'>
                                                    <div>
                                                        <TbDiscount2 style={{ fontSize: "30px" }} />
                                                        <span className='mobile-home-span-3 ps-2 pe-2'>Offer</span>
                                                    </div>
                                                </Link>
                                            )}
                                            {user && user.success && user?.data?.auth_token? (
                                                <Link to="/my-profile" className='text-dark'>
                                                    <div>
                                                        <CgProfile style={{ fontSize: "30px" }} />
                                                    </div>
                                                </Link>
                                            ) : (
                                                <Link to="/signup" className='text-dark'>
                                                    <div>
                                                        <CgProfile style={{ fontSize: "30px" }} />
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                    <div className='w-75 mobile-home-span-2 mt-2' >
                                        <Link to={'/set-location'} style={{ color: '#000' }}>
                                            <div >
                                                <Truncate lines={1} >
                                                    {address?.house ? address?.house + "," : ''}{" " + address?.address}
                                                </Truncate>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className='px-3 sticky-search'>
                                    <Link to="/explore-menu">
                                        <div className="d-flex flex-row align-items-center mobile-searchBar py-2 px-2">
                                            <input type="text"
                                                className="mobile-inventory-search w-100"
                                                placeholder="What do you want to order ?"
                                            />
                                            <span className="">
                                                <BiSearch set="curved" style={{ fontSize: "1.7em", color: "#FEA24B", }} />
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                                {medicine?.allStores?.length > 0 || medicine?.nearbanners?.length > 0 || medicine?.topstores?.length > 0 ||
                                    medicine?.item?.length > 0 || mostOrders?.items?.length > 0 || topDeals?.allDeals?.length > 0 ||
                                    nearMedicines?.data?.length > 0 || recommendedMedicines?.data?.length > 0 ? (
                                    <div>
                                        <MedOffer Sliders={medicine} />
                                        <div className="d-flex flex-row justify-content-between align-items-center px-3 with-prescription py-3 mt-5" >
                                            <div className="d-flex align-items-center">
                                                <IoNewspaperOutline size={20} color={'#9a4d00'} />
                                                <span className="ps-1" style={{ fontWeight: '650', fontSize: '1em', color: '#9a4d00' }}>
                                                    Order with prescription
                                                </span>
                                            </div>
                                            <Link to={user && user.success ? "/with-prescription" : "/signup"}>
                                                <div className="d-flex align-items-center">
                                                    <span className="pe-1" style={{ fontWeight: '700', fontSize: '1.2em', color: '#fd8814' }}>
                                                        Order Now!
                                                    </span> <BsFillArrowRightCircleFill size={20} color={'#fd8814'} />
                                                </div>
                                            </Link>
                                        </div>
                                        <MedicineGroup />
                                        <MedicineDealsToday />
                                        <TopStore allStores={medicine} />
                                        <MostOrderMedicines mostOrders={mostOrders} />
                                        <PopularBrands />
                                        <TopDeal />
                                        <StoreNearYou />
                                        <RecommendedStore recommendedMedicines={recommendedMedicines} />
                                        <AllMedicalStore />
                                        {/* <AllMedicalStore Stores={this.state.allStores} /> */}
                                    </div>
                                ) : (
                                    <div className='mt-5 d-flex flex-column align-items-center'>
                                        <div className='mt-5 pt-3 d-flex flex-column align-items-center'>
                                            <img src={map} alt="no items" style={{ objectFit: 'cover', width: '70%' }} />
                                        </div>
                                        <span className='mt-4 fw-bolder fs-6'>Sorry!</span>
                                        <span className='mt-1 fw-bolder fs-6'>We don't deliver to your location.</span>
                                    </div>
                                )}
                            </div>
                            <Footer active_medicine={true} />
                        </React.Fragment>
                    )}

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    medicine: state.medicine.medicine,
    topDeals: state.medicine.topDeals,
    itemGroup: state.medicine.itemGroup,
    mostOrders: state.medicine.mostOrders,
    cartProduct: state.cart.products,
    recommendedMedicines: state.medicine.recommendedMedicines,
    nearMedicines: state.medicine.nearMedicines,
});
export default connect(mapStateToProps, {
    getAllMedicineData, getAllTopDeals, getMostOrders, getAllRecommendedMedicines
})(Medicines);
