 

export const WEBSITE_URL = "https://grosavbeta.howincloud.com/public/api";   //for localhost route uses
export const WEBSITE_BASE_URL = "https://grosavbeta.howincloud.com/public/";   //for localhost route uses
export const IMAGE_BASE_URL = "https://grosavbeta.howincloud.com/public";   //for localhost route uses
export const ITEM_SHARE_URL = "https://grosavbeta.howincloud.com";   //for localhost route uses


// export const WEBSITE_URL = "http://grosav-backend-final.test/api";   //for localhost route uses
// export const WEBSITE_BASE_URL = "http://grosav-backend-final.test/public/";   //for localhost route uses
// export const IMAGE_BASE_URL = "http://grosav-backend-final.test/public";   //for localhost route uses
// export const ITEM_SHARE_URL = "http://grosav-backend-final.test";   //for localhost route uses


// export const WEBSITE_URL = "https://grosav.com/public/api";   //for localhost route uses
// export const WEBSITE_BASE_URL = "https://grosav.com/public/";   //for localhost route uses
// export const IMAGE_BASE_URL = "https://grosav.com/public";   //for localhost route uses
// export const ITEM_SHARE_URL = "https://grosav.com";   //for localhost route uses
 export const ITEM_SHARE_URL2 = "https://grosav.com/public/assets/";   //for localhost route uses



//-----------------------Customer Api Start---------------------------------

//login
export const SEND_OTP_URL = WEBSITE_URL + '/send-otp';
export const LOGIN_OTP_USER_URL = WEBSITE_URL + "/login-otp-user";
export const LOGIN_OTP_USER_UPDATE_URL = WEBSITE_URL + "/update-otp-user";

export const REGISTER_USER_URL = WEBSITE_URL + "/register-user";
export const UPDATE_USER_DETAILS_URL = WEBSITE_URL + "/update-user-details";
export const UPDATE_USER_REDUX_INFO_URL = WEBSITE_URL + "/update-user-redux-info";

//referral
export const CHECK_REFERRAL_URL = WEBSITE_URL + '/check-referral-code';
export const GET_SIMILAR_ITEMS = WEBSITE_URL + '/get-similar-items';
export const GET_ALL_ITEM_COUPONS = WEBSITE_URL + '/get-all-item-coupons';

//cart
export const GET_CART_PRODUCTS_URL = WEBSITE_URL + '/get-cart-products';
export const GET_USER_OFFERS_URL = WEBSITE_URL + "/view-user-coupons";
export const APPLY_COUPON_URL = WEBSITE_URL + "/apply-coupon";
export const AUTO_APPLY_COUPON_URL = WEBSITE_URL + "/auto-apply-coupon";
export const GET_CALCULATE_STORE_MAX_DISTANCE = WEBSITE_URL + "/store-distance";
export const GET_CALCULATE_STORE_STORE_CHARGE_TAX = WEBSITE_URL + "/calculate-store-charge-tax";
export const GET_DELIVERY_SLAB_URL = WEBSITE_URL + "/get-distance-delivery-slab"
export const GET_STORE_INFO_AND_OPERATIONAL_STATUS_URL = WEBSITE_URL + "/get-store-operational";

//home
export const GET_SETTINGS_URL = WEBSITE_URL + "/get-settings";
export const GET_COUPONS_URL = WEBSITE_URL + "/get-all-coupons";
export const GET_MY_COUPON_URL = WEBSITE_URL + "/get-my-coupons";
export const GET_SLIDERS_URL = WEBSITE_URL + "/get-all-sliders";
export const GET_ALL_STORE_CATEGORIES_URL = WEBSITE_URL + "/get-all-store-categories";
export const GET_SINGLE_SLIDER_URL = WEBSITE_URL + "/get-single-slider";
export const GET_All_BANNER_URL = WEBSITE_URL + "/get-all-banner-for-food";
export const GET_TOP_FOOD_URL = WEBSITE_URL + "/get-all-top-food";
export const GET_SINGLE_COUPON_URL = WEBSITE_URL + "/get-single-coupon";
export const CALCULATE_STORE_MAX_DISTANCE_URL = WEBSITE_URL + "/store-distance"
export const GET_SEARCH_CATEGORIES_URL = WEBSITE_URL + '/get-all-search-categories';
export const GET_TOP_DEALS_URL = WEBSITE_URL + '/get-all-top-deals';
export const GET_POPULAR_BRANDS_URL = WEBSITE_URL + '/get-popular-brands';
export const GET_All_RESTURANTS_URL = WEBSITE_URL + '/get-all-resturants';
export const GET_ALL_RECOMMENTED_STORES_URL = WEBSITE_URL + '/get-all-recommented-stores';
export const GET_ALL_NEAR_STORES_URL = WEBSITE_URL + '/get-all-near-stores';
export const GET_ALL_POPULAR_STORES_URL = WEBSITE_URL + '/get-all-top-stores';
export const GET_ALL_ITEM_CATEGORY_URL = WEBSITE_URL + '/get-all-item-categories';
export const GET_ALL_ITEM_GROUP_URL = WEBSITE_URL + '/get-all-item-group';

//deals today
export const GET_DEALS_TODAY_URL = WEBSITE_URL + '/get-deals-today'

// subscription
export const GET_ALL_PLANS_URL = WEBSITE_URL + '/get-all-plans';
export const GET_SINGLE_PLAN_URL = WEBSITE_URL + '/get-single-plan';
export const SUBSCRIPTION_PLACE_URL = WEBSITE_URL + '/place-subscription';
export const PLAN_PAYTM_PAYMENT_URL = WEBSITE_URL + "/payment/plan-paytm";


export const PAYTM_SUBSCRIPTION_PAYMENT_URL = WEBSITE_URL + "/process-plan-paytm";
export const PAYTM_SUBSCRIPTION_STATUS_CHECK_URL = WEBSITE_URL + "/payment/status-plan-paytm";


// wallet
export const GET_USER_WALLET_URL = WEBSITE_URL + "/get-user-wallet";

// scratch card
export const GET_ALL_SCRATCH_CARD_URL = WEBSITE_URL + "/get-all-scratch-cards";
export const COMPLETED_SCRATCH_CARD_URL = WEBSITE_URL + "/complete-scratch-cards";

//refer and earn
export const GET_MY_REFFERALS_URL = WEBSITE_URL + "/get-reffer-and-earn";

//item
export const GET_SEARCH_MOBILE_ITEMS_URL = WEBSITE_URL + "/get-mobile-search-items"
export const GET_TOGGLE_FAV_ITEM_URL = WEBSITE_URL + '/toggle-fav-item'
export const GET_ALL_LIKED_ITEMS_URL = WEBSITE_URL + '/get-all-liked-items'
export const GET_SINGLE_ITEM_URL = WEBSITE_URL + '/get-mobile-single-item'
export const GET_REMOVE_FAV_ITEM_URL = WEBSITE_URL + '/remove-liked-item'
export const GET_SINGLE_ITEM_GROUP_URL = WEBSITE_URL + '/get-single-item-group'


// medicine
export const GET_ALL_MEDICINE_DATA_URL = WEBSITE_URL + '/get-all-medicine-data'
export const GET_MEDICINE_NEAR_STORES_URL = WEBSITE_URL + '/get-medicine-near-stores'
export const GET_ALL_TOP_DEALS_URL = WEBSITE_URL + '/get-all-top-deals-medicine'
export const GET_MOST_ORDERS_URL = WEBSITE_URL + '/get-most-orders'
export const GET_ALL_RECOMMENDED_MEDICINE_URL = WEBSITE_URL + '/get-all-recommended-medicine'

//order with prescription
export const GET_ALL_MEDICAL_STORES_URL = WEBSITE_URL + '/get-all-medical-stores'
export const ORDER_WITH_PRESCRIPTION_URL = WEBSITE_URL + '/order-with-prescription'
export const GET_ALL_PRESCRIPTION_IMAGES_URL = WEBSITE_URL + "/get-all-prescription-images"
export const ADD_PRESCRIPTION_IMAGE_URL = WEBSITE_URL + "/add-with-prescription-image";
export const REMOVE_PRESCRIPTION_IMAGE_URL = WEBSITE_URL + "/remove-with-prescription-image";
export const GET_PRESCRIPTION_DELIVERY_CHARGE_URL = WEBSITE_URL + "/get-prescription-delivery-charge";
export const GET_NEAR_STORES_URL = WEBSITE_URL + "/get-near-stores";

// user
export const GET_USER_DETAILS_URL = WEBSITE_URL + "/get-all-user-details";
export const GET_DEFAULT_ADDRESS_URL = WEBSITE_URL + "/get-default-address";
export const SAVE_ADDRESS_URL = WEBSITE_URL + "/save-address";
export const DELETE_ADDRESS_URL = WEBSITE_URL + "/delete-address";
export const EDIT_USER_DETAILS_URL = WEBSITE_URL + "/edit-user-details";
export const GET_ALL_USER_ADDRESS_URL = WEBSITE_URL + "/get-all-user-address";
export const UPDATE_DEFAULT_ADDRESS_URL = WEBSITE_URL + "/update-default-address";
export const DELETE_USER_URL = WEBSITE_URL + "/delete-user";

//store
export const GET_SINGLE_STORE_URL = WEBSITE_URL + '/get-single-store';
export const GET_LATEST_STORE_INFO_URL = WEBSITE_URL + '/get-single-store-cart';

export const GET_STORE_CATEGORY_URL = WEBSITE_URL + '/get-store-category';


//store category
export const GET_STORE_CATEGORY_TOP_ITEMS_URL = WEBSITE_URL + '/get-store-category-top-items';
export const GET_STORE_CATEGORY_STORES_URL = WEBSITE_URL + '/get-store-category-stores';
export const GET_STORE_CATEGORY_ITEM_CATEGORY_URL = WEBSITE_URL + '/get-store-category-item-category';
export const GET_STORE_CATEGORY_BANNER_URL = WEBSITE_URL + '/get-store-category-banners';
export const GET_STORE_CATEGORY_TOP_STORES_URL = WEBSITE_URL + '/get-store-category-top-stores';
export const GET_STORE_CATEGORY_TOP_DEALS_URL = WEBSITE_URL + '/get-store-category-top-deals';
export const GET_STORE_CATEGORY_RECOMMENDED_STORES_URL = WEBSITE_URL + '/get-store-category-recommended-stores';
export const GET_STORE_CATEGORY_NEAR_STORES_URL = WEBSITE_URL + '/get-store-category-near-stores';
export const GET_ORDER_RATING_URL = WEBSITE_URL + '/get-order-rating';
export const GET_SINGLE_DEAL_URL = WEBSITE_URL + "/get-single-deal";
export const GET_SINGLE_BANNER_URL = WEBSITE_URL + "/get-single-banner";
export const GET_ALL_CATEGORY_URL = WEBSITE_URL + '/get-all-category';
export const GET_STORE_CATEGORY_TODAY_DEALS_URL = WEBSITE_URL + '/get-store-category-today-deals';

// PAYTM_PAYMENT_URL
export const PAYTM_PAYMENT_URL = WEBSITE_URL + "/payment/paytm";
export const PAYTM_MEMBER_PAYMENT_URL = WEBSITE_URL + "/paytm-member-payment";
export const PAYTM_STATUS_CHECK_URL = WEBSITE_URL + "/payment/status-paytm";

// RAZORPAY
export const PLAN_PROCESS_RAZORPAY_URL = WEBSITE_URL + "/payment/process-razorpay-subscription";
export const PLAN_PROCESS_STATUS_UPDATE_RAZORPAY_URL = WEBSITE_URL + "/payment/process-razorpay-subscription-state-update";

export const RAZORPAY_ORDER_URL = WEBSITE_URL + "/payment/process-razorpay-order";
export const ORDER_PROCESS_STATUS_UPDATE_RAZORPAY_URL = WEBSITE_URL + "/payment/process-razorpay-order-state-update";

export const RAZORPAY_COURIER_URL = WEBSITE_URL + "/payment/process-razorpay-courier";
export const COURIER_PROCESS_STATUS_UPDATE_RAZORPAY_URL = WEBSITE_URL + "/payment/process-razorpay-courier-state-update";


export const RAZORPAY_ANYSTORE_URL = WEBSITE_URL + "/payment/process-razorpay-any-store";
export const ANYSTORE_PROCESS_STATUS_UPDATE_RAZORPAY_URL = WEBSITE_URL + "/payment/process-razorpay-any-store-state-update";


// export const RAZORPAY_MEMBER_PAYMENT_URL = WEBSITE_URL + "/razorpay-member-payment";
// export const RAZORPAY_STATUS_CHECK_URL = WEBSITE_URL + "/payment/status-razorpay";

// checkout
export const PLACE_ORDER_URL = WEBSITE_URL + "/place-order";

// running order
export const GET_CUSTOMER_ORDER_STATUS_URL = WEBSITE_URL + "/get-completed-order";
export const GET_ALL_RUNNING_ORDERS_URL = WEBSITE_URL + "/get-all-running-orders";
export const CANCEL_ORDER_URL = WEBSITE_URL + "/cancel-order";
export const ACCEPT_ORDER_URL = WEBSITE_URL + "/accept-order";
export const CANCEL_COURIER_URL = WEBSITE_URL + "/cancel-courier";
export const GET_ORDERS_TO_REVIEW_URL = WEBSITE_URL + "/get-orders-to-review"
export const SUBMIT_ORDER_REVIEW_URL = WEBSITE_URL + "/submit-order-review"
export const GET_SINGLE_ORDER_URL = WEBSITE_URL + "/get-single-order"
export const ADD_STORE_REVIEW_URL = WEBSITE_URL + "/add-store-review"


//courier
export const CHECK_COURIER_PICKUP_ADDRESS_URL = WEBSITE_URL + "/check-courier-pickup-address";
export const CHECK_COURIER_DELIVERY_ADDRESS_URL = WEBSITE_URL + "/check-courier-delivery-address";
export const COURIER_CHECK_OUT_URL = WEBSITE_URL + "/courier-checkout";
export const COURIER_STATUS_URL = WEBSITE_URL + "/courier-status";
export const GET_ADDRESS_CITY_COURIER = WEBSITE_URL + "/courier/get-address-city";

export const COURIER_PAYTM_PAYMENT_URL = WEBSITE_URL + "/courier/paytm";
export const COURIER_PAYTM_STATUS_CHECK_URL = WEBSITE_URL + "/courier/status-paytm";


// location
export const GET_ADDRESS_FROM_COORDINATES_URL = WEBSITE_URL + "/get-address-to-coordinates";

// Any Store
export const CHECK_STORE_ADDRESS_URL = WEBSITE_URL + "/check-store-address";
export const CHECK_DELIVERY_ADDRESS_URL = WEBSITE_URL + "/check-delivery-address";
export const PLACE_ANY_STORE_ORDER_URL = WEBSITE_URL + "/place-any-store-oreder";
export const PICKUP_STATUS_URL = WEBSITE_URL + "/pickup-status";
export const GET_ALL_ANY_STORE_COURIER_CATEGORY_URL = WEBSITE_URL + "/get-all-any-store-courier-category";
export const ANY_STORE_PAYTM_PAYMENT_URL = WEBSITE_URL + "/anystore/paytm";
export const ANY_STORE_PAYTM_STATUS_CHECK_URL = WEBSITE_URL + "/anystore/status-paytm";
export const GET_ADDRESS_CITY_ANYSTORE = WEBSITE_URL + "/anystore/get-address-city";
export const GET_PAGES_URL = WEBSITE_URL + "/get-pages";



//-----------------------Customer Api End---------------------------------




//-----------------------Vendor Api Start---------------------------------


//login
export const LOGIN_STORE_USER_URL = WEBSITE_URL + "/store-owner/login";
export const SEND_STORE_USER_OTP_URL = WEBSITE_URL + '/store-owner/send-otp';
export const LOGIN_OTP_STORE_USER_URL = WEBSITE_URL + "/store-owner/login-otp-user";
export const GET_USER_PASSWORD_URL = WEBSITE_URL + "/get-acc-pass";
export const GET_TOGGLE_DISPLAY_TOKEN_URL = WEBSITE_URL + "/get-toggle-display-orders";
export const DELETE_DEVICE_TOKEN_URL = WEBSITE_URL + "/delete-device-token";


//user store
export const GET_USER_URL = WEBSITE_URL + '/get-account-details';
export const GET_USER_STORES_URL = WEBSITE_URL + '/get-user-stores';
export const TOGGLE_SINGLE_STORE_URL = WEBSITE_URL + '/toggle-single-store';
export const VIEW_ALL_CITIES_URL = WEBSITE_URL + "/get-cities";
export const VIEW_ALL_STORE_CATEGORIES_URL = WEBSITE_URL + "/get-store-categories";
export const GET_VENDOR_REPORT_DATA_URL = WEBSITE_URL + "/get-vendor-report-data";
export const CHANGE_PREPARATION_TIME_URL = WEBSITE_URL + "/change-preparation-time";
export const GET_STORE_USERS_URL = WEBSITE_URL + "/get-store-users";
export const GET_STORE_CATEGORIES_URL = WEBSITE_URL + "/get-store-categories";
export const GET_STORE_RATING_URL = WEBSITE_URL + "/get-store-rating";


//order
export const GET_VENDOR_SINGLE_ORDER_URL = WEBSITE_URL + '/get-vendor-single-order';
export const GET_ORDER_URL = WEBSITE_URL + '/get-order-details'
export const ACCEPT_VENDOR_ORDER_URL = WEBSITE_URL + '/accept-vendor-order'
export const DECLINE_VENDOR_ORDER_URL = WEBSITE_URL + '/decline-vendor-order'
export const PREPARE_VENDOR_ORDER_URL = WEBSITE_URL + '/prepare-vendor-order';
export const PICKUP_VENDOR_ORDER_URL = WEBSITE_URL + '/pickup-vendor-order';
export const GET_ALL_PAST_ORDERS_URL = WEBSITE_URL + "/get-all-past-orders";
export const GET_ORDER_STATUS_URL = WEBSITE_URL + "/get-all-ongoing-orders";
export const GET_ORDER_PRESCRIPTION_URL = WEBSITE_URL + "/get-order-prescription";
export const GET_SCHEDULE_DATE_URL = WEBSITE_URL + "/get-schedule-data";
export const ADD_SCHEDULE_DATE_URL = WEBSITE_URL + "/add-schedule-date";
export const GET_CATEGORY_SCHEDULE_DATE_URL = WEBSITE_URL + "/get-category-schedule-data";
export const ADD_CATEGORY_SCHEDULE_DATE_URL = WEBSITE_URL + "/add-category-schedule-data";
export const GET_PAYOUTS_URL = WEBSITE_URL + "/date-wise-payout";
export const GET_NOTIFICATIONS_URL = WEBSITE_URL + '/get-notifications'
export const GET_SEARCH_ORDERS_URL = WEBSITE_URL + "/get-search-orders";

//with prescription
// export const GET_PRESCRIPTION_IMAGES_URL = WEBSITE_URL + "/get-prescription-images";
export const COMPELETE_PRESCRIPTION_ORDER_URL = WEBSITE_URL + "/compelete-prescription-order";
export const COMPELETE_PRESCRIPTION_ORDER_NEW_URL = WEBSITE_URL + "/compelete-prescription-order-new";


//inventory
export const GET_ITME_SCHEDULE_DATE_URL = WEBSITE_URL + "/get-item-schedule-data";
export const ADD_ITEM_SCHEDULE_DATE_URL = WEBSITE_URL + "/add-item-schedule-data";

export const GET_ITME_CATEGORY_SCHEDULE_DATE_URL = WEBSITE_URL + "/get-item-category-schedule-data";
export const ADD_ITEM_CATEGORY_SCHEDULE_DATE_URL = WEBSITE_URL + "/add-item-category-schedule-data";

export const GET_ITEMS_URL = WEBSITE_URL + '/get-items';
export const GET_SEARCH_ITEMS_URL = WEBSITE_URL + "/get-search-items";
export const GET_TOGGLE_ITEM_URL = WEBSITE_URL + "/get-toggle-item";
export const GET_VENDOR_SINGLE_ITEM_URL = WEBSITE_URL + "/get-vendor-single-item";
export const GET_ITEM_CATEGORIES_URL = WEBSITE_URL + "/get-all-vendor-item-categories";
export const GET_VENDOR_SINGLE_ITEM_CATEGORY_URL = WEBSITE_URL + "/get-vendor-single-item-category";
export const GET_CATEGORY_FILTER_URL = WEBSITE_URL + "/get-category-items";
export const GET_ADDON_CATEGORIES_URL = WEBSITE_URL + "/get-all-addon-categories";
export const GET_TOGGLE_ADDON_CATEGORY_URL = WEBSITE_URL + "/get-toggle-addon-category";
export const GET_ADDONS_URL = WEBSITE_URL + '/get-addons';
export const GET_VENDOR_SINGLE_ADDON_URL = WEBSITE_URL + "/get-vendor-single-addon";
export const GET_SEARCH_ADDONS_URL = WEBSITE_URL + "/get-search-addons";
export const GET_TOGGLE_ADDON_URL = WEBSITE_URL + "/get-toggle-addon";
export const GET_SEARCH_ADDONS_CATEGORIES_URL = WEBSITE_URL + "/get-search-addon-categories";
export const GET_VENDOR_SINGLE_ADDON_CATEGORY_URL = WEBSITE_URL + "/get-vendor-single-addon-category";
export const GET_TOGGLE_ITEM_CATEGORY_URL = WEBSITE_URL + "/get-toggle-item-category";
export const GET_SEARCH_ITEM_CATEGORIES_URL = WEBSITE_URL + "/get-search-item-categories";
export const ADD_VENDOR_ITEM_URL = WEBSITE_URL + "/add-vendor-item";
export const EDIT_VENDOR_ITEM_URL = WEBSITE_URL + "/edit-vendor-item";
export const ADD_ITEM_CATEGORY_URL = WEBSITE_URL + "/add-item-category";
export const EDIT_ITEM_CATEGORY_URL = WEBSITE_URL + "/edit-item-category";
export const ADD_ADDON_CATEGORY_URL = WEBSITE_URL + "/add-addon-category";
export const EDIT_ADDON_CATEGORY_URL = WEBSITE_URL + "/edit-addon-category";
export const ADD_VENDOR_ADDON_URL = WEBSITE_URL + "/add-vendor-addon";
export const EDIT_VENDOR_ADDON_URL = WEBSITE_URL + "/edit-vendor-addon";

//coupon
export const ADD_VENDOR_COUPON_URL = WEBSITE_URL + '/add-vendor-coupon';
export const UPDATE_VENDOR_COUPON_URL = WEBSITE_URL + '/update-vendor-coupon';
export const UPDATE_ITEM_DISCOUNT_URL = WEBSITE_URL + '/update-item-discount';
export const GET_ALL_VENDOR_COUPON_URL = WEBSITE_URL + '/get-all-vendor-coupon';
export const GET_SINGLE_VENDOR_COUPON_URL = WEBSITE_URL + '/get-single-vendor-coupon';
export const UPDATE_ALL_STORE_ITEM_DISCOUNT_URL = WEBSITE_URL + '/update-all-store-item-discount';

//export
export const EXPORT_DATE_WISE_SALES_URL = WEBSITE_URL + "/date-wise-order";
export const GET_EXPORTED_DATE_WISE_SALES_URL = WEBSITE_URL + "/view-date-wise-order";

//deal today
export const GET_ALL_STORE_ITEMS_URL = WEBSITE_URL + "/get-all-store-items"
export const ADD_DEAL_OF_TODAY_URL = WEBSITE_URL + "/add-deal-of-today"
export const CHANGE_DEAL_STATUS_URL = WEBSITE_URL + "/change-deal-status"
export const CHANGE_ITEM_STATUS_URL = WEBSITE_URL + "/change-item-status"

// schedule timing
export const CHANGE_ITEM_SCHEDULE_STATUS_URL = WEBSITE_URL + "/update-item-schedule-data"
export const CHANGE_STORE_SCHEDULE_STATUS_URL = WEBSITE_URL + "/update-store-schedule-data"

export const CHANGE_ITEMS_SCHEDULE_STATUS_URL = WEBSITE_URL + "/update-items-schedule-data-status"
export const CHANGE_ITEMS_CATEGORY_SCHEDULE_STATUS_URL = WEBSITE_URL + "/update-item-category-schedule-data-status"






//-----------------------Vendor Api End---------------------------------



 export const PLACE_HOLDER_IMAGE = ITEM_SHARE_URL2 + '/blue_placeholder.webp'


